export default [
  { type: "GO", gridArea: "random1" },
  { type: "LEVEL", gridArea: "cell1", number: 1 },
  { type: "RAND_CHANCE", gridArea: "cell2", number: 2 },
  { type: "LEVEL", gridArea: "cell3", number: 3 },
  { type: "LEVEL", gridArea: "cell4", number: 4 },
  { type: "RAND_PERSON", gridArea: "cell5", number: 5 },
  { type: "STORY", gridArea: "cell6", number: 6 },
  { type: "LEVEL", gridArea: "cell7", number: 7 },
  { type: "RAND_CHANCE", gridArea: "cell8", number: 8 },
  { type: "LEVEL", gridArea: "cell9", number: 9 },
  { type: "LEVEL", gridArea: "cell10", number: 10 },
  { type: "JAIL", gridArea: "random2" },
  { type: "LEVEL", gridArea: "cell11", number: 11 },
  { type: "RAND_PERSON", gridArea: "cell12", number: 12 },
  { type: "LEVEL", gridArea: "cell13", number: 13 },
  { type: "LEVEL", gridArea: "cell14", number: 14 },
  { type: "RAND_CHANCE", gridArea: "cell15", number: 15 },
  { type: "STORY", gridArea: "cell16", number: 16 },
  { type: "LEVEL", gridArea: "cell17", number: 17 },
  { type: "LEVEL", gridArea: "cell18", number: 18 },
  { type: "RAND_PERSON", gridArea: "cell19", number: 19 },
  { type: "LEVEL", gridArea: "cell20", number: 20 },
  { type: "GATE", gridArea: "random3" },
  { type: "LEVEL", gridArea: "cell21", number: 21 },
  { type: "RAND_CHANCE", gridArea: "cell22", number: 22 },
  { type: "LEVEL", gridArea: "cell23", number: 23 },
  { type: "LEVEL", gridArea: "cell24", number: 24 },
  { type: "RAND_PERSON", gridArea: "cell25", number: 25 },
  { type: "STORY", gridArea: "cell26", number: 26 },
  { type: "LEVEL", gridArea: "cell27", number: 27 },
  { type: "RAND_CHANCE", gridArea: "cell28", number: 28 },
  { type: "LEVEL", gridArea: "cell29", number: 29 },
  { type: "LEVEL", gridArea: "cell30", number: 30 },
  { type: "MYSTERY", gridArea: "random4" },
  { type: "LEVEL", gridArea: "cell31", number: 31 },
  { type: "RAND_CHANCE", gridArea: "cell32", number: 32 },
  { type: "LEVEL", gridArea: "cell33", number: 33 },
  { type: "LEVEL", gridArea: "cell34", number: 34 },
  { type: "STORY", gridArea: "cell35", number: 35 },
  { type: "RAND_PERSON", gridArea: "cell36", number: 36 },
  { type: "LEVEL", gridArea: "cell37", number: 37 },
  { type: "RAND_CHANCE", gridArea: "cell38", number: 38 },
  { type: "LEVEL", gridArea: "cell39", number: 39 },
  { type: "LEVEL", gridArea: "cell40", number: 40 },
  { type: "STORY", gridArea: "cell41", number: 41 },
  { type: "LEVEL", gridArea: "cell42", number: 42 },
  { type: "RAND_CHANCE", gridArea: "cell43", number: 43 },
  { type: "LEVEL", gridArea: "cell44", number: 44 },
  { type: "LEVEL", gridArea: "cell45", number: 45 },
  { type: "RAND_PERSON", gridArea: "cell46", number: 46 },
  { type: "LEVEL", gridArea: "cell47", number: 47 },
  { type: "RAND_CHANCE", gridArea: "cell48", number: 48 },
  { type: "LEVEL", gridArea: "cell49", number: 49 },
  { type: "STORY", gridArea: "cell50", number: 50 },
  { type: "LEVEL", gridArea: "cell51", number: 51 },
  { type: "RAND_CHANCE", gridArea: "cell52", number: 52 },
  { type: "LEVEL", gridArea: "cell53", number: 53 },
  { type: "LEVEL", gridArea: "cell54", number: 54 },
  { type: "RAND_PERSON", gridArea: "cell55", number: 55 },
  { type: "LEVEL", gridArea: "cell56", number: 56 },
  { type: "RAND_CHANCE", gridArea: "cell57", number: 57 },
  { type: "LEVEL", gridArea: "cell58", number: 58 },
  { type: "STORY", gridArea: "cell59", number: 59 },
  { type: "LEVEL", gridArea: "cell60", number: 60 },
  { type: "RAND_CHANCE", gridArea: "cell61", number: 61 },
  { type: "LEVEL", gridArea: "cell62", number: 62 },
  { type: "LEVEL", gridArea: "cell63", number: 63 },
  { type: "RAND_PERSON", gridArea: "cell64", number: 64 },
  { type: "LEVEL", gridArea: "cell65", number: 65 },
  { type: "RAND_CHANCE", gridArea: "cell66", number: 66 },
  { type: "LEVEL", gridArea: "cell67", number: 67 },
  { type: "STORY", gridArea: "cell68", number: 68 },
  { type: "LEVEL", gridArea: "cell69", number: 69 },
  { type: "LEVEL", gridArea: "cell70", number: 70 },
  { type: "RAND_CHANCE", gridArea: "cell71", number: 71 },
  { type: "LEVEL", gridArea: "cell72", number: 72 },
  { type: "LEVEL", gridArea: "cell73", number: 73 },
  { type: "LEVEL", gridArea: "cell74", number: 74 },
  { type: "LEVEL", gridArea: "cell75", number: 75 },
  { type: "GATEI", gridArea: "cell76", number: 76 },
  { type: "CENTER", gridArea: "middle" },
];
