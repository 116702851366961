import React from "react";
import styled from "styled-components";

const Container = styled.div`
  grid-area: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 500;
`;

const NavLogoSvg = styled.svg`
  height: 250px;
  width: auto;
  color: inherit;
  transform: translateX(-2vh);
  color: ${(p) => (p.selected ? "#fff" : "#545454")};
`;

export default ({ selected }) => (
  <Container>
    <NavLogoSvg
      width="399"
      height="400"
      viewBox="0 0 399 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      selected={selected}
    >
      <path
        d="M385.4 42.8C356.4 24.3 322 13.5 285 13.5C182 13.5 98.5 97 98.5 200C98.5 303 182.1 386.5 285 386.5C321.7 386.5 355.8 375.9 384.7 357.7"
        stroke="currentColor"
        strokeWidth="27"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.7 200H279.2"
        stroke="currentColor"
        strokeWidth="15"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M251.3 233.6L284.9 200L251.3 166.4"
        stroke="currentColor"
        strokeWidth="12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M214.6 233.6L248.3 200L214.6 166.4"
        stroke="currentColor"
        strokeWidth="12"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.29999 235.9L42.3 200L6.29999 164.1"
        stroke="currentColor"
        strokeWidth="12"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </NavLogoSvg>
  </Container>
);
